import moment from 'moment';
import { capitalize } from 'lodash';
import { trackEvent, gaTracker, ecTracker } from 'user-analytics';
import {
  passengerCount,
  getPlaceType,
  encodePassengers,
  isOldSearchPassengersFormat,
} from 'utils/Reserbus';
import { CurrentLang } from 'utils/lang';
import store from '@/store';
import { removeURLParam } from 'utils/queryString/urlsCleaner';
import { SEARCH_CHANGED } from 'constants/TrackEvents';
import { serializeEcTrip, serializeTrip } from './trip';

function serializePlace(place) {
  let name = place ? place.name : '';
  const slug = place ? place.id : '';
  let terminalName = place ? 'Todas las Terminales' : '';

  if (place && getPlaceType(place.id) === 'terminal') {
    name = place.cityName;
    terminalName = place.name;
  }
  return { name, slug, terminalName };
}

/**
 * Default attributes for user search.
 *
 * @param {Object} params - The parameters for the search.
 * @param {Object} params.origin - The origin of the search.
 * @param {Object} params.destination - The destination of the search.
 * @param {string} params.departureDate - The departure date of the search.
 * @param {boolean} params.openTicket - Whether the ticket is open or not.
 * @param {Object} params.passengers - The passengers for the search.
 * @returns {Object} - The default attributes for the search.
 */
function defaultAttributes({ origin, destination, departureDate, openTicket, passengers }) {
  const departureMoment = openTicket ? null : moment(departureDate);
  const departureDelta = openTicket
    ? null
    : moment.duration(departureMoment - moment().startOf('day')).asDays();

  const passengersOnSearch =
    passengers && !isOldSearchPassengersFormat(encodePassengers(passengers));

  const {
    name: originCityName,
    terminalName: originTerminalName,
    slug: originSlug,
  } = serializePlace(origin);

  const {
    name: destinationCityName,
    terminalName: destinationTerminalName,
    slug: destinationSlug,
  } = serializePlace(destination);

  return {
    'Origin': originCityName,
    'Destination': destinationCityName,
    'Origin Slug': originSlug,
    'Destination Slug': destinationSlug,
    'Origin Terminal': originTerminalName,
    'Destination Terminal': destinationTerminalName,
    'Departure': departureMoment ? departureMoment.format() : null,
    'Departure Delta': parseInt(departureDelta, 10),
    'Route': `${originCityName} - ${destinationCityName}`,
    'Passengers on Search': passengersOnSearch,
  };
}

function returnAttributes(search) {
  if (search.roundTrip) {
    const departureMoment = moment(search.departureDate);
    const returnMoment = moment(search.returnDate);
    const tripLength = moment.duration(returnMoment - departureMoment).asDays();

    return {
      'Return': returnMoment.toDate(),
      'Trip Length': parseInt(tripLength, 10),
    };
  }

  return {};
}

export function serializeTransportData(departureData, returnData) {
  const { busCount, flightCount, mixedCount, busActive, flightActive, mixedActive } = departureData;
  let transportData = {
    'Bus Count': busCount,
    'Flight Count': flightCount,
    'Multicarrier Count': mixedCount,
    'Has Frequent Buses': busActive,
    'Has Frequent Flights': flightActive,
    'Has Frequent Multicarrier': mixedActive,
  };

  if (returnData) {
    const { busCount, flightCount, mixedCount, busActive, flightActive, mixedActive } = returnData;
    transportData = Object.assign(transportData, {
      'Return Bus Count': busCount,
      'Return Flight Count': flightCount,
      'Return Multicarrier Count': mixedCount,
      'Return Has Frequent Buses': busActive,
      'Return Has Frequent Flights': flightActive,
      'Return Has Frequent Multicarrier': mixedActive,
    });
  }

  return transportData;
}

export function serializeSearchDetails(departureData, returnData) {
  const detailsData = {
    'Departure Search Duration': departureData.searchDuration,
    'Departure Search ID': departureData.parentSearchId,
  };

  if (returnData) {
    Object.assign(detailsData, {
      'Return Search Duration': returnData.searchDuration,
      'Return Search ID': returnData.parentSearchId,
    });
  }

  return detailsData;
}

export function serialize(search, extra = {}) {
  return {
    ...defaultAttributes(search),
    ...returnAttributes(search),
    ...extra,
    Language: CurrentLang(),
  };
}

function getAnalyticsParams(queryParams) {
  const prefix = 'analytics_';
  const params = {};
  Array.from(queryParams.keys()).forEach((param) => {
    if (param.startsWith(prefix)) {
      const key = param
        .replace(prefix, '')
        .split('_')
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1))
        .join(' ');
      const value = queryParams.get(param);
      params[key] = value;
    }
  });
  return params;
}

export function searchTracker(search, queryParams) {
  const { origin, destination, passengers, lodgingProvider, openTicket } = search;
  const { adult, child, infant } = passengers;
  const totalPassengers = passengerCount(passengers);
  const extra = {
    'from': 'Home',
    'Passengers': totalPassengers,
    'Lodging Provider': capitalize(lodgingProvider),
    'Open Ticket': openTicket,
    ...getAnalyticsParams(queryParams),
  };

  const {
    name: originName,
    terminalName: originTerminalName,
    slug: originSlug,
  } = serializePlace(origin);

  const {
    name: destinationName,
    terminalName: destinationTerminalName,
    slug: destinationSlug,
  } = serializePlace(destination);

  trackEvent('Search', serialize(search, extra));

  gaTracker.trackSearch({
    ...search,
    originName,
    originSlug,
    destinationSlug,
    destinationName,
    originTerminalName,
    destinationTerminalName,
    adult,
    child,
    infant,
    passengers: totalPassengers,
  });
}

export function viewResultsTracker(
  { search, departureData, returnData },
  queryParams,
  hasRedirectTrips,
) {
  const { features } = store.getState().whitelabelConfig;
  const lastAction = queryParams.get('action');
  const priceAlertRedirected = queryParams.get('price_alert');
  const nearbyTerminal = queryParams.get('nearby');
  const analyticsData = {
    ...serialize(search),
    ...serializeTransportData(departureData, returnData),
    ...serializeSearchDetails(departureData, returnData),
    ...(features.TRIP_REDIRECTION_LEAD && { 'Has Redirect Buses': hasRedirectTrips }),
    ...(features.SHOW_MIN_PRICES && { 'Day Navigation Action': lastAction }),
    ...(features.USE_NERBY_TERMINALS && { 'Nearby Terminal': nearbyTerminal }),
    ...(priceAlertRedirected && { 'From Price Alert': priceAlertRedirected }),
  };

  if (queryParams.has('redirected_to')) {
    trackEvent('Email Link Clicked', {
      ...analyticsData,
      'Email Type': queryParams.get('redirected_to'),
    });
    window?.history.replaceState({}, null, removeURLParam('redirected_to', window?.location.href));
    queryParams.delete('redirected_to');
  }

  trackEvent('View Results', analyticsData);
}

export function interestInRedirect({ departureTrip, returnTrip, roundTrip }) {
  const analyticsData = {
    'Origin': departureTrip ? departureTrip.getIn(['origin', 'name']) : '',
    'Destination': departureTrip ? departureTrip.getIn(['destination', 'name']) : '',
    'Departure Date': departureTrip ? departureTrip.get('departure') : '',
    'Return Date': returnTrip ? returnTrip.get('departure') : '',
    'roundTrip': roundTrip,
  };

  trackEvent('Interest in Redirect', analyticsData);
}

export function searchCouponTracker(couponCode) {
  trackEvent('Use Search Coupon', { 'Coupon Code': couponCode });
}

export function productListTracker(transportType, trips, providerName, passengers, way) {
  const listName = `Organic - Search Results - ${providerName} - ${capitalize(way)}`;
  const productList = trips.map((trip, index) => serializeEcTrip(trip, index + 1));

  ecTracker.trackProductList(listName, productList, passengers, way);
}

export function priceAlertTracker(search, userData) {
  const { userType = 'Anonymous', wantsNewsletter } = search;

  trackEvent('Price Alert', serialize(search, userData));
  gaTracker.trackSignUp('Price Alert', 'Pop Up', userType);

  if (wantsNewsletter) {
    gaTracker.trackSignUp('Newsletter', 'Pop Up', userType);
  }
}

export function filterTracker(search, filterBy, list) {
  trackEvent('Filter Results', serialize(search, { By: filterBy, List: list }));
}

export function sortTracker(search, sortBy, list) {
  trackEvent('Sort Results', serialize(search, { By: sortBy, List: list }));
}

export function resultsActionsTracker(search, action, list) {
  trackEvent('Results Actions', serialize(search, { Action: action, List: list }));
}

export function trackOpenTickedModalOpened(search, from) {
  trackEvent('Open Ticket Modal Shown', serialize(search, { From: from }));
}

export function trackDiscountModalOpened(search) {
  trackEvent('Discount Modal Shown', serialize(search));
}

export function trackDiscountModalClosed(search) {
  trackEvent('Discount Modal Closed', serialize(search));
}

export function trackDiscountModalNewSearch(search) {
  trackEvent('Discount Modal New Search', serialize(search));
}

/**
 * Event to track when the user changes the search
 */
export function trackSearchChanged({ searchType }) {
  trackEvent(SEARCH_CHANGED, { 'Search Type': searchType });
}

/**
 * Track the event when a user views seats.
 *
 * @param {Object} trip - The trip object.
 * @param {string} way - The direction of the trip.
 */
export function trackViewSeats(trip, way) {
  trackEvent('View Seats', serializeTrip(trip, { Way: way }));
}

/**
 * Track the activation of trip price alerts.
 *
 * @param {String} nameEvent - the name of event 'Price Alert Created' |'Price Alert Opened' | 'Price Alert Redirected'
 * @param {Object} options - The options for tracking.
 * @param {Object} options.search - The search data.
 * @param {boolean} hasRedirectTrips - Indicates if there are redirect trips.
 */
export function trackTripPriceAlerts(
  nameEvent,
  search,
  isTripAlertRedirect,
  { phoneNumber = '', email = '', from = '', lowestPrice = '' } = {},
) {
  const serializedSearch = serialize(search);
  const analyticsData = {
    ...serializedSearch,
    'Anticipation': serializedSearch['Departure Delta'],
    'Email': email,
    'Whatsapp': phoneNumber,
    'From': from,
    'Lowest Price': lowestPrice,
    ...(isTripAlertRedirect && { 'From Price Alert': isTripAlertRedirect }),
  };
  trackEvent(nameEvent, analyticsData);
}
