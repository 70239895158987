import creditCardType from 'credit-card-type';
import flex from '@cybersource/flex-sdk-web';

const cardCodes = {
  'visa': '001',
  'mastercard': '002',
  'american-express': '003',
  'discover': '004',
  'diners-club': '005',
  'carte-blanche': '006',
  'jcb': '007',
  'maestro': '024',
  'elo': '054',
  'hipercard': '050',
};

function mappedCyberResponse(cyberResponse, cardParams) {
  return {
    credit_card_tokens: {
      cybersource_token: cyberResponse.token,
    },
    card_number: cyberResponse.maskedPan,
    card_holder_name: cardParams.holderName,
    card_exp_month: cardParams.expirationMonth,
    card_exp_year: cardParams.expirationYear,
    card_brand: creditCardType(cardParams.cardNumber)[0].type,
    card_cvv: cardParams.cvv2,
  };
}

function CyberSource() {
  let productionMode = false;

  function loadAntifraudBeacon({ orgId, sessionId, merchantId }) {
    return () => {
      const url = `//h.online-metrix.net/fp/tags.js?org_id=${orgId}&session_id=${merchantId}${sessionId}`;
      const s = document.createElement('script');
      s.type = 'text/javascript';
      s.async = false;
      s.defer = true;
      s.src = url;
      const x = document.getElementsByTagName('script')[0];
      x.parentNode.insertBefore(s, x);
    };
  }

  function initialize({ isSandbox, orgId, merchantId, sessionId }) {
    productionMode = !isSandbox;

    if (document.readyState === 'complete') {
      loadAntifraudBeacon({ orgId, sessionId, merchantId })();
    } else if (window.attachEvent) {
      window.attachEvent('onload', loadAntifraudBeacon({ orgId, sessionId, merchantId }));
    } else {
      window.addEventListener('load', loadAntifraudBeacon({ orgId, sessionId, merchantId }), false);
    }
  }

  function createToken(cardParams, paymentGatewayToken) {
    return new Promise((resolve, reject) => {
      const jwk = paymentGatewayToken;

      const {
        cardNumber,
        expirationMonth: cardExpirationMonth,
        expirationYear: cardExpirationYear,
        cvv2: securityCode,
      } = cardParams;

      const cardType = creditCardType(cardNumber);
      const cardTypeCode = cardCodes[cardType[0].type];

      const options = {
        kid: jwk.kid,
        keystore: jwk,
        encryptionType: 'RsaOaep', // ensure this matches the encryptionType you specified when creating your key
        production: productionMode,
        cardInfo: {
          cardNumber,
          cardType: cardTypeCode,
          cardExpirationMonth,
          cardExpirationYear,
          securityCode,
        },
      };

      flex.createToken(options, (response) => {
        if (response.error) {
          reject(response.error);
          return;
        }
        const result = mappedCyberResponse(response, cardParams);
        console.log("===>", result); // Imprimir el resultado con prefijo
        resolve(result);
      });
    });
  }

  return { initialize, createToken, loadAntifraudBeacon };
}

const cybersource = CyberSource();
export default cybersource;
