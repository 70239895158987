/* eslint-disable no-extra-boolean-cast */
/* global $ */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { connect } from 'react-redux';
import { decodePassengers } from 'utils/Reserbus';
import {
  productListTracker,
  resultsActionsTracker,
  sortTracker,
  filterTracker,
} from 'metrics/user-analytics/search';
import { selectedTripTracker } from 'metrics/user-analytics/trip';
import { Visibility, Spacing, Text } from '@reservamos/elements';
import LoadingScreen from 'components/LoadingScreen';
import TripListHeader from 'components/TripListHeader';
import TripList from 'components/search/TripList';
import { getMomentFromSearchDateParam } from 'utils/search/searchDateParam';
import DiscountBanner from 'ui/atoms/DiscountBanner';
import withGrowthBookFeatures from 'components/GrowthBookProvider/withGrowthBookFeatures';
import { When } from 'react-if';
import LineBanner from 'ui/atoms/LineBanner';
import { trackRecommendedTripSelected } from 'utils/userPreferences';
import RouteTerminalsDisplay from 'ui/molecules/RouteTerminalsDisplay';
import { getSeatsPurchasePayload, isSeatsOnResultActivated } from 'utils/seats';
import NoResults from '../../../ui/molecules/NoResults';
import SearchSidebar from '../../SearchSidebar';
import LabelLoading from '../../../ui/molecules/LabelLoading';
import NormalTicketButton from '../NormalTicketButton';
import NormalTicketWrapper from '../NormalTicketWrapper';
import Coupon from '../SearchCoupon/SearchCoupon';
import ChangeProvider from '../../../ui/atoms/ChangeProvider';
import OpenTicketList from '../OpenTicketList';
import DayControls from '../DayControls';
import NerbyTerminals from '../../../ui/atoms/NerbyTerminals';
import ResultsTitle from '../../../ui/atoms/ResultsTitle';
import DepartureTripPicked from '../../../ui/molecules/DepartureTripPicked';
import { cleanAnalyticsParams } from '../../../utils/urls';
import RecommendedTripsList from '../RecommendedTrips';
import SwitchDoters from '../SwitchDoters/SwitchDoters';
import OpenTicketCTA from '../OpenTicketCTA';

const propTypes = {
  origin: PropTypes.object.isRequired,
  destination: PropTypes.object.isRequired,
  departureId: PropTypes.string.isRequired,
  passengers: PropTypes.string.isRequired,
  departureDate: PropTypes.string.isRequired,
  returnDate: PropTypes.string,
  providers: PropTypes.array,
  provider: PropTypes.object,
  transportType: PropTypes.string,
  searchIsPolling: PropTypes.bool.isRequired,
  paymentPlans: PropTypes.objectOf(PropTypes.number).isRequired,
  installmentsMinAmount: PropTypes.number.isRequired,
  trips: PropTypes.array.isRequired,
  noTrips: PropTypes.bool.isRequired,
  sortBy: PropTypes.string.isRequired,
  sortProvidersBy: PropTypes.string,
  departureFilter: PropTypes.string.isRequired,
  locations: PropTypes.object.isRequired,
  departureLocationFilter: PropTypes.string.isRequired,
  arrivalLocationFilter: PropTypes.string.isRequired,
  activeDepartureFilter: PropTypes.bool.isRequired,
  activeLocationFilter: PropTypes.bool.isRequired,
  activeStopsFilter: PropTypes.bool.isRequired,
  tooManyFilters: PropTypes.bool.isRequired,
  currentPath: PropTypes.string.isRequired,
  seenPrice: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  setSortOrder: PropTypes.func.isRequired,
  setTripFilter: PropTypes.func.isRequired,
  resetTripFilters: PropTypes.func.isRequired,
  stopsFilter: PropTypes.func.isRequired,
  transitionTo: PropTypes.func.isRequired,
  replaceUrl: PropTypes.func.isRequired,
  hasMultipleProviders: PropTypes.bool.isRequired,
  openTrip: PropTypes.object,
  isStopsFilterAvailable: PropTypes.object,
  categoriesFilter: PropTypes.array,
  tripOptionsFilter: PropTypes.array,
  t: PropTypes.func.isRequired,
  discountedTripsByCoupon: PropTypes.array,
  isOpenTicketList: PropTypes.bool,
  setRedirectParams: PropTypes.func,
  isLogged: PropTypes.bool,
  providerId: PropTypes.string,
  areOpenTicketTrips: PropTypes.bool,
  features: PropTypes.object.isRequired,
  returnIsOpenTicket: PropTypes.bool,
  tripDate: PropTypes.string,
  tripDepartureDuration: PropTypes.string,
  tripTime: PropTypes.string,
  originCity: PropTypes.string,
  destinationCity: PropTypes.string,
  redirectToDeparture: PropTypes.func,
  departureTrip: PropTypes.object,
  setTripFilters: PropTypes.func.isRequired,
  tripsWithoutFilters: PropTypes.array,
  filters: PropTypes.object,
  searchOriginId: PropTypes.string,
  searchDestinationId: PropTypes.string,
  discountPercent: PropTypes.number,
  growthBookFeatures: PropTypes.object.isRequired,
  resultPriceToShow: PropTypes.bool,
  firstTripLineId: PropTypes.string,
  firstTripLineLogo: PropTypes.string,
  brand: PropTypes.string,
  tripTerminalInfo: PropTypes.object,
  departureQueryTripInfo: PropTypes.object,
  passengersNotFound: PropTypes.array,
  couponCode: PropTypes.string,
  passengersQueryParam: PropTypes.string,
  hasValidDepartureSeats: PropTypes.array,
  newPurchase: PropTypes.func,
  location: PropTypes.object,
  roundTrip: PropTypes.bool.isRequired,
};

/**
 * Represents the ProviderReturns component.
 */
class ProviderReturns extends Component {
  /**
   * Constructs a new ProviderReturns component.
   * @param {object} props - The component props.
   */
  constructor(props) {
    super(props);

    this.optionsClick = this.optionsClick.bind(this);
    this.setSortOrder = this.setSortOrder.bind(this);
    this.setActiveFilter = this.setActiveFilter.bind(this);
    this.setActiveFilters = this.setActiveFilters.bind(this);
    this.selectTrip = this.selectTrip.bind(this);
    this.selectTripDefault = this.selectTripDefault.bind(this);
    this.selectTripRedirect = this.selectTripRedirect.bind(this);
    this.goToHome = this.goToHome.bind(this);
    this.goToReturnProviders = this.goToReturnProviders.bind(this);
    this.selectOpenTripList = this.selectOpenTripList.bind(this);
    this.useOpenTicketIfAvailable = this.useOpenTicketIfAvailable.bind(this);
    this.handleOnRedirectToDeparture = this.handleOnRedirectToDeparture.bind(this);
    this.departureSelectedTripChecked = false;
  }

  componentDidMount() {
    const { trips } = this.props;

    this.validateDepartureSeats();

    $(window).scrollTop(0);
    /*
      FIXME correct the scroll issue with the address bar
    */
    setTimeout(() => $(window).scrollTop(0), 300);

    if (trips.length) {
      this.trackProductList();
    }
    cleanAnalyticsParams();
    this.useOpenTicketIfAvailable();
  }

  componentDidUpdate(prevProps) {
    const { trips, provider } = this.props;

    this.validateDepartureSeats();

    if (provider && prevProps.trips.length !== trips.length) {
      this.trackProductList();
    }

    if (prevProps.trips.length !== trips.length) {
      this.useOpenTicketIfAvailable();
    }
  }

  componentWillUnmount() {
    const { resetTripFilters, features } = this.props;
    if (!features.NEW_FILTERS_VERSION) resetTripFilters();
  }

  handleOnRedirectToDeparture() {
    const { features, redirectToDeparture } = this.props;
    redirectToDeparture(features.PROVIDERS_SELECTION_ENABLED);
  }

  getAnalyticsData() {
    const { origin, destination, departureDate, returnDate } = this.props;

    return {
      origin,
      destination,
      roundTrip: true,
      departureDate: moment(departureDate, 'DD-MMM-YY').toDate(),
      returnDate: moment(returnDate, 'DD-MMM-YY').toDate(),
    };
  }

  setSortOrder(sortBy, list) {
    const { setSortOrder } = this.props;
    setSortOrder(sortBy, list);
    sortTracker(this.getAnalyticsData(), sortBy, 'Returns');
  }

  setActiveFilter(filterBy, active) {
    const { setTripFilter } = this.props;
    setTripFilter(filterBy, active);
    filterTracker(this.getAnalyticsData(), active, 'Returns');
  }

  /**
   * Applied several filters to the search
   * @param {Object} filters - Filters applied
   * @param {*} isFiltersUpdating - Indicates if it is an update of filters but not made by the user
   */
  setActiveFilters(filters, isFiltersUpdating) {
    const { setTripFilters } = this.props;
    setTripFilters(filters);
    if (!isFiltersUpdating) filterTracker(this.getAnalyticsData(), filters, 'Departures');
  }

  /**
   * Validates if the seats selected for the departure trip are valid,
   * this is check if the seats are selected in results page
   */
  validateDepartureSeats() {
    const { redirectToDeparture, hasValidDepartureSeats, departureTrip } = this.props;
    const isSeatsOnResults = isSeatsOnResultActivated();
    if (
      !this.departureSelectedTripChecked &&
      departureTrip?.id &&
      isSeatsOnResults &&
      !hasValidDepartureSeats
    ) {
      this.departureSelectedTripChecked = true;
      redirectToDeparture();
    }
  }

  useOpenTicketIfAvailable() {
    const { openTrip, returnIsOpenTicket, isOpenTicketList, features } = this.props;
    // Only retrieve open ticket if flag is true
    if (!returnIsOpenTicket) return;

    if (!isOpenTicketList && features.OPEN_TICKET_PROVIDER_SELECTION_ENABLED) {
      this.selectOpenTripList();
      return;
    }
    if (openTrip) {
      this.selectTrip(openTrip);
    }
  }

  trackProductList() {
    const { transportType, trips, provider, passengers } = this.props;

    // Check if provider is undefined
    if (provider === undefined) {

      return; // Optionally, return or handle the case where provider is undefined
    }

    const { name } = provider; // This is now safe because you've checked that provider is not undefined

    productListTracker(transportType, trips, name, decodePassengers(passengers), 'return');
  }

  optionsClick(option, modalComponent) {
    const { showModal } = this.props;

    showModal(modalComponent);
    resultsActionsTracker(this.getAnalyticsData(), option, 'Returns');
  }

  selectTripDefault(trip, position) {
    const { departureId, passengers, transitionTo, replaceUrl, seenPrice, returnIsOpenTicket } =
      this.props;
    const route = `/purchase/${departureId}/${trip.id}/new/${passengers}`;
    const roundSeenPrice = Number(seenPrice) + Number(trip.pricing.total);

    selectedTripTracker(trip, position, 'return');
    if (returnIsOpenTicket) {
      replaceUrl(route, { seenPrice: roundSeenPrice });
    } else {
      transitionTo(route, {
        seenPrice: roundSeenPrice,
      });
    }
  }

  selectTripRedirect(trip) {
    const {
      roundTrip,
      setRedirectParams,
      passengersQueryParam,
      newPurchase,
      location,
      departureTrip,
    } = this.props;
    const { redirectTo, departure, originId, destinationId, lineId, id } = trip;
    const { brand } = redirectTo;

    const departureDate = moment(departure);

    const selectedSeats =
      isSeatsOnResultActivated() &&
      getSeatsPurchasePayload({
        departureId: departureTrip.id,
        returnId: id,
      });
    // Used to fetch the slug of the depart trip
    const searchParams = new URLSearchParams(location.search);
    const departsSlug = searchParams.get('departs');

    const params = {
      return_origin: originId,
      return_destination: destinationId,
      return_date: departureDate.format('DD-MM-YYYY'),
      return_hour: departureDate.format('HH'),
      return_minutes: departureDate.format('mm'),
      return_line: lineId,
      passengers: passengersQueryParam,
      selected_seats: selectedSeats && JSON.stringify(selectedSeats),
    };

    const shouldRedirectNow = true;
    const waitForPurchase = true;
    newPurchase(departsSlug, trip.id, trip.pricing.total);

    setRedirectParams('return', params, brand, shouldRedirectNow, roundTrip, waitForPurchase);
  }

  selectTrip(trip, position) {
    const { features } = this.props;
    const { redirectTo, recommendationType } = trip;
    if (recommendationType) {
      trackRecommendedTripSelected({ way: 'return', recommendationType });
    }

    if (features.TRIP_REDIRECTION_LEAD && redirectTo) {
      return this.selectTripRedirect(trip);
    }

    return this.selectTripDefault(trip, position);
  }

  selectOpenTripList() {
    const {
      passengers,
      transitionTo,
      departureDate,
      departureId,
      returnDate,
      searchOriginId,
      searchDestinationId,
    } = this.props;
    const route =
      `/search/${searchOriginId}/${searchDestinationId}/${departureDate}/${returnDate}` +
      `/p/${passengers}/${departureId}/returns/open`;

    transitionTo(route);
  }

  goToHome() {
    const { transitionTo } = this.props;
    transitionTo('/');
  }

  goToReturnProviders() {
    const { currentPath, replaceUrl, features } = this.props;
    const route = currentPath.split('/');

    // Remove current provider id from currentPath array and add 'provider' to go
    // to return providers route
    route.pop();
    route.pop();

    if (features.PROVIDERS_SELECTION_ENABLED) {
      route.push('return', 'providers');
    } else {
      route.push('returns');
    }

    replaceUrl(route.join('/'));
  }

  hasFiltersApplied() {
    const { filters } = this.props;
    return (
      !filters.departureTime.includes('none') ||
      filters.categories.length ||
      !filters.stops.includes('none')
    );
  }

  renderTripList(openTicket = null) {
    const {
      isOpenTicketList,
      isLogged,
      providerId,
      departureTrip,
      filters,
      tripsWithoutFilters,
      features,
      passengersNotFound,
      couponCode,
      discountedTripsByCoupon,
    } = this.props;

    const showOpenTicket =
      !this.hasFiltersApplied() || (this.hasFiltersApplied() && !tripsWithoutFilters.length);

    return isOpenTicketList ? (
      <OpenTicketList way="return" selectTrip={this.selectTrip} roundTrip providerId={providerId} />
    ) : (
      <Spacing responsiveSize="S" vertical>
        <RecommendedTripsList
          couponCode={couponCode}
          discountedTripsByCoupon={discountedTripsByCoupon}
          way="return"
          selectTrip={this.selectTrip}
          onOptionClick={this.optionsClick}
          providerId={providerId}
          filtersApplied={filters}
          departureTrip={departureTrip}
        />

        <TripList
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...this.props}
          way="return"
          selectTrip={this.selectTrip}
          onOptionClick={this.optionsClick}
          tripOpenTicket={showOpenTicket && openTicket}
          isLogged={isLogged}
          filtersApplied={filters}
          showPoweredBy={!tripsWithoutFilters.length}
          departureTrip={departureTrip}
          showDotersBanner={!tripsWithoutFilters.length && !isLogged && features.DOTERS_ENABLED}
          passengersNotFound={passengersNotFound}
        />
      </Spacing>
    );
  }

  renderTripListWithoutFilters(openTicket = null) {
    const { tripsWithoutFilters, isLogged, filters, isOpenTicketList, features, departureTrip } =
      this.props;
    if (isOpenTicketList) return null;
    return (
      <TripList
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...this.props}
        trips={tripsWithoutFilters}
        way="return"
        selectTrip={this.selectTrip}
        onOptionClick={this.optionsClick}
        tripOpenTicket={openTicket}
        isLogged={isLogged}
        filtersApplied={filters}
        isNotFiltered
        showPoweredBy={Boolean(tripsWithoutFilters.length)}
        showDotersBanner={tripsWithoutFilters.length && !isLogged && features.DOTERS_ENABLED}
        departureTrip={departureTrip}
      />
    );
  }

  render() {
    const {
      provider,
      providers,
      sortBy,
      sortProvidersBy,
      departureFilter,
      departureLocationFilter,
      arrivalLocationFilter,
      activeStopsFilter,
      noTrips,
      locations,
      searchIsPolling,
      showModal,
      activeLocationFilter,
      activeDepartureFilter,
      tooManyFilters,
      openTrip,
      stopsFilter,
      categoriesFilter,
      tripOptionsFilter,
      trips,
      isStopsFilterAvailable,
      isOpenTicketList,
      t,
      providerId,
      areOpenTicketTrips,
      features,
      returnDate,
      returnIsOpenTicket,
      tripDate,
      tripDepartureDuration,
      tripTime,
      originCity,
      destinationCity,
      departureTrip,
      resetTripFilters,
      tripsWithoutFilters,
      discountPercent,
      growthBookFeatures,
      resultPriceToShow,
      firstTripLineId,
      firstTripLineLogo,
      brand,
      tripTerminalInfo,
    } = this.props;
    const hasResults = isOpenTicketList || !noTrips || (noTrips && tooManyFilters);

    const returnMoment = getMomentFromSearchDateParam(returnDate);
    const returnDateText = `${returnMoment.format('DD')}-${returnMoment
      .format('MMMM')
      .slice(0, 3)}-${returnMoment.format('YY')}`;

    if ((searchIsPolling && noTrips) || (returnIsOpenTicket && !isOpenTicketList)) {
      return <LoadingScreen loadingText={t('general:searching')} hasHeader={false} />;
    }

    let openTicketCTA = (
      <OpenTicketCTA
        openTrip={openTrip}
        way="return"
        isRoundTrip
        showWalletPoints={resultPriceToShow.priceType === 'wallet'}
        walletType={resultPriceToShow.walletType}
        onSelect={
          features.OPEN_TICKET_PROVIDER_SELECTION_ENABLED
            ? this.selectOpenTripList
            : this.selectTrip
        }
      />
    );
    if (isOpenTicketList && openTrip) {
      openTicketCTA = (
        <NormalTicketWrapper>
          <NormalTicketButton way="return" onClick={this.goToReturnProviders} />
        </NormalTicketWrapper>
      );
    }

    const TerminalsComponent = <NerbyTerminals onColumns />;
    const showDaysOpenList = !isOpenTicketList || areOpenTicketTrips;

    const scheduleTitle = isOpenTicketList ? 'openReturn' : 'schedulesReturn';
    const activeFiltersFunction = features.NEW_FILTERS_VERSION
      ? this.setActiveFilters
      : this.setActiveFilter;

    const lineOrDiscountBannerAreShown =
      features.SHOW_DISCOUNT_BANNER || features.EXTRA_RESULT_LINES?.includes(firstTripLineId);

    const isNewResultsDesign = growthBookFeatures.newResultsDesign;
    const isSeatsOnResults = isSeatsOnResultActivated();

    let useSearchSidebar = features.SEARCH_SIDEBAR_ENABLED;
    if ((brand === 'berlinas' && isNewResultsDesign) || isSeatsOnResults) useSearchSidebar = false;

    return (
      <>
        {hasResults ? (
          <>
            <div
              className={`returns-container ${
                useSearchSidebar && isNewResultsDesign && !isSeatsOnResults ? 'with-sidebar' : ''
              }`}
            >
              <DepartureTripPicked
                departureDate={tripDate}
                departureDuration={tripDepartureDuration}
                departureTime={tripTime}
                originCity={originCity}
                destinationCity={destinationCity}
                onClick={this.handleOnRedirectToDeparture}
                departureLineLogo={departureTrip.line?.logoUrl}
                isOpenTicket={departureTrip.openTicket}
              />
              {!useSearchSidebar && (
                <>
                  <TripListHeader
                    way="return"
                    provider={provider}
                    hasOtherProviders={providers.length > 1}
                    sortOn={!!sortBy}
                    departureFilterOn={activeDepartureFilter}
                    locationFilterOn={activeLocationFilter}
                    stopsFilterOn={activeStopsFilter}
                    onOptionClick={this.optionsClick}
                    showLocationFilter={
                      locations.departure.length > 2 || locations.arrival.length > 2
                    }
                    locations={locations}
                    floatingOptions={
                      2 + (locations.departure.length > 2 || locations.arrival.length > 2)
                    }
                    sortProperty={sortBy}
                    providerSortProperty={sortProvidersBy}
                    activeDeparture={departureFilter}
                    departureLocation={departureLocationFilter}
                    arrivalLocation={arrivalLocationFilter}
                    onSortSelect={this.setSortOrder}
                    onFilterSelect={activeFiltersFunction}
                    showModal={showModal}
                    onChangeProviderClick={this.goToReturnProviders}
                    stopsFilter={stopsFilter}
                    trips={trips}
                    date={returnDateText}
                    isStopsFilterAvailable={isStopsFilterAvailable}
                    isOpenTicketList={isOpenTicketList}
                    onResetFilters={resetTripFilters}
                    isPolling={searchIsPolling}
                  />

                  {isNewResultsDesign && (
                    <>
                      <Spacing
                        justifyContent={lineOrDiscountBannerAreShown ? 'space-between' : 'flex-end'}
                        size="S"
                      >
                        <When condition={features.EXTRA_RESULT_LINES?.includes(firstTripLineId)}>
                          <LineBanner brand={firstTripLineId} logoUrl={firstTripLineLogo} />
                        </When>
                        <When
                          condition={
                            features.SHOW_DISCOUNT_BANNER &&
                            !features.EXTRA_RESULT_LINES?.includes(firstTripLineId)
                          }
                        >
                          <DiscountBanner discountPercent={discountPercent} />
                        </When>
                        <SwitchDoters />
                      </Spacing>
                      <br />
                    </>
                  )}
                </>
              )}
              {useSearchSidebar && (
                <>
                  <div className="sidebar-title">
                    {features.USE_HORIZONTAL_RESULTS ? (
                      <p className="sidebar-title-copy">{t('trips:label.discounts_available')}</p>
                    ) : (
                      <p className="sidebar-title-copy">{t('search:label.sort_by')}</p>
                    )}
                  </div>
                  <SearchSidebar
                    onChangeSort={this.setSortOrder}
                    onChangeScheduleFilter={activeFiltersFunction}
                    scheduleFilter={departureFilter}
                    categoriesFilter={categoriesFilter}
                    tripOptionsFilter={tripOptionsFilter}
                    isStopsFilterAvailable={isStopsFilterAvailable}
                    stopsFilter={stopsFilter}
                    sort={sortBy}
                    way="return"
                    hasOtherProviders={providers.length > 1}
                    onChangeProviderClick={this.goToReturnProviders}
                    onResetFilters={resetTripFilters}
                    isOpenTicketList={isOpenTicketList}
                    isPolling={searchIsPolling}
                  />
                  <ResultsTitle
                    sectionTitle={scheduleTitle}
                    date={!isOpenTicketList && returnDateText}
                    rightContent={isNewResultsDesign ? <SwitchDoters /> : openTicketCTA}
                  />
                </>
              )}

              <div className="results-container">
                <Spacing vertical size="S">
                  {Boolean(searchIsPolling) && <LabelLoading />}
                  {hasResults || isOpenTicketList ? this.renderTripList(openTicketCTA) : null}
                  {features.NEW_FILTERS_VERSION &&
                  tripsWithoutFilters.length &&
                  hasResults &&
                  !isOpenTicketList ? (
                    <>
                      <Text weight="bold" size="L" mobileSize="M">
                        {t('search:label.other_trips_you_may_be_interested_on')}
                      </Text>
                      {this.renderTripListWithoutFilters(openTicketCTA)}
                    </>
                  ) : null}

                  {tripTerminalInfo.hasCoords && (
                    <RouteTerminalsDisplay
                      terminalOrigin={tripTerminalInfo.originTerminal}
                      cityOrigin={tripTerminalInfo.originCity}
                      terminalDestination={tripTerminalInfo.destinationTerminal}
                      cityDestination={tripTerminalInfo.destinationCity}
                      originUrl={tripTerminalInfo.originMapUrl}
                      destinationUrl={tripTerminalInfo.destinationMapUrl}
                    />
                  )}

                  <Spacing vertical size="S">
                    <div className="results-bottom-container fade-in">
                      {Boolean(!searchIsPolling) && hasResults && (
                        <Text size="S" color="grayLight">
                          <em>{t('general:showing_all_available_trips')}</em>
                        </Text>
                      )}
                    </div>
                    <div className="results-bottom-container fade-in">
                      {features.COUPON_ON_SEARCH && <Coupon />}
                      {!isOpenTicketList && features.PROVIDERS_SELECTION_ENABLED && (
                        <ChangeProvider
                          hasOtherProviders={providers.length > 1}
                          onChangeProviderClick={this.goToReturnProviders}
                        />
                      )}
                    </div>
                  </Spacing>

                  <Visibility type="hideForLargeOnly">{TerminalsComponent}</Visibility>
                  {features.SHOW_MIN_PRICES && showDaysOpenList && (
                    <DayControls way="return" providerId={providerId} fixed />
                  )}
                </Spacing>
              </div>
            </div>
          </>
        ) : (
          <NoResults way="return" providerId={providerId} />
        )}
      </>
    );
  }
}

ProviderReturns.propTypes = propTypes;

ProviderReturns.defaultProps = {
  isOpenTicketList: false,
  setRedirectParams: () => {},
};

const mapStateToProps = (state) => ({
  features: state.whitelabelConfig.features,
});

export default connect(mapStateToProps)(
  withGrowthBookFeatures(ProviderReturns, 'new_results_design'),
);
